import React from 'react';
import Slider from 'react-slick';
import loadable from '@loadable/component';
import { reviewSliderStatic } from '../../static-data';

const ReviewSlide = loadable(() => import('./ReviewSlide'));

/**
 * @return {JSX.Element}
 * @constructor
 */

const ReviewSlider = () => {
  const settings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    pauseOnFocus: false,
    focusOnSelect: true,
    cssEase: 'ease-in-out',
    centerMode: true,
    centerPadding: '8%',
    responsive: [{ breakpoint: 768, settings: { centerMode: false, centerPadding: '' }}],
  };
  const components = reviewSliderStatic.map(review => <ReviewSlide payload={review} />);

  return (
    <Slider className='review-carousel' {...settings}>
      {React.Children.toArray(components)}
    </Slider>
  );
};

export default ReviewSlider;
